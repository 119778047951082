import React, { useState, useRef, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { SIGNUP } from '../utils/mutations';
import Auth from '../utils/auth';
import { quantum } from 'ldrs'
import '../App.css';

quantum.register()

const Onboard = (props) => {
  const [loading, setLoading] = useState(false);

  function generateRandomBase64String(length) {
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
  
  // Generate a random base 64 32 character string
  var randomBase64StringUser = generateRandomBase64String(16);
  var randomBase64StringPassword = generateRandomBase64String(16);

  const [formState, setFormState] = useState({
    username: randomBase64StringUser,
    password: randomBase64StringPassword,
  });
  const [signUp, { error, data }] = useMutation(SIGNUP);

  // update state based on form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  // submit form
  const handleFormSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    console.log(formState)
    console.log(event);
    try {
      const { data } = await signUp({
        variables: { ...formState },
      });
      console.log(data);
      console.log(formState);
      if (data.signUp.token !== null) {
        await Auth.login(data.signUp.token);
        props.setIsRegisterPaneOpen(false);
      }
    
    } catch (e) {
      console.log(e);
    }
  };


const DynamicText = () => {
  const messages = [
    "Imagine a community facing frequent power outages.",
    "Imagine a city dealing with water shortages.",
    "Imagine a neighborhood struggling with internet disruptions.",
    "Imagine a rural area facing healthcare access challenges.",
    "Imagine a family affected by frequent transportation delays.",
    "Imagine a community facing extreme air pollution levels.",
    "Imagine a town dealing with frequent natural disasters.",
    "Imagine a school with outdated technology for learning.",
    "Imagine a hospital struggling with medical equipment shortages.",
    "Imagine a city with inadequate waste management systems.",
    "Imagine a rural area with limited access to clean drinking water.",
    "Imagine a neighborhood affected by rising crime rates.",
    "Imagine a family unable to afford housing due to high rent costs.",
    "Imagine a community dealing with increased traffic congestion.",
    "Imagine a town suffering from frequent flooding.",
    "Imagine a village with limited access to education.",
    "Imagine a community experiencing food shortages.",
    "Imagine a school with overcrowded classrooms.",
    "Imagine a neighborhood facing frequent power surges.",
    "Imagine a region with scarce job opportunities.",
    "Imagine a hospital unable to meet the demand for emergency care.",
    "Imagine a rural area facing deforestation and environmental degradation.",
    "Imagine a community struggling with rising healthcare costs.",
    "Imagine a city impacted by noise pollution.",
    "Imagine a neighborhood affected by frequent road accidents.",
    "Imagine a town with poor infrastructure for public transport.",
    "Imagine a school without enough teachers.",
    "Imagine a village struggling with unreliable internet access.",
    "Imagine a community affected by frequent wildfires.",
    "Imagine a city with increasing homelessness rates.",
    "Imagine a region suffering from prolonged drought.",
    "Imagine a family living in an area with high levels of industrial pollution.",
    "Imagine a neighborhood struggling with drug addiction problems.",
    "Imagine a town with poor sanitation and hygiene facilities.",
    "Imagine a rural area affected by declining agricultural productivity.",
    "Imagine a city dealing with high rates of unemployment.",
    "Imagine a school unable to provide meals to its students.",
    "Imagine a community without reliable emergency services.",
    "Imagine a hospital running out of essential medicines.",
    "Imagine a neighborhood with limited access to green spaces.",
    "Imagine a town facing an influx of refugees without proper infrastructure.",
    "Imagine a village affected by frequent landslides.",
    "Imagine a rural area struggling with a lack of modern farming equipment.",
    "Imagine a community without proper mental health services.",
    "Imagine a city suffering from frequent industrial accidents.",
    "Imagine a neighborhood dealing with gentrification and displacement.",
    "Imagine a school with limited resources for special needs students.",
    "Imagine a town lacking affordable childcare options.",
    "Imagine a village with poor access to transportation.",
    "Imagine a rural area facing challenges due to climate change.",
    "Imagine a community experiencing frequent power cuts.",
    "Imagine a family living in an overcrowded urban environment.",
    "Imagine a city with insufficient healthcare facilities.",
    "Imagine a neighborhood suffering from noise disturbances.",
    "Imagine a town with limited disaster preparedness plans.",
    "Imagine a village lacking clean energy alternatives.",
    "Imagine a rural area affected by desertification.",
    "Imagine a city grappling with income inequality.",
    "Imagine a community with high rates of domestic violence.",
    "Imagine a neighborhood struggling with access to fresh produce.",
    "Imagine a town facing frequent road closures due to construction.",
    "Imagine a school with outdated textbooks.",
    "Imagine a hospital dealing with a shortage of doctors.",
    "Imagine a city facing severe water pollution.",
    "Imagine a rural area affected by lack of proper healthcare.",
    "Imagine a neighborhood dealing with frequent sewage leaks.",
    "Imagine a village without access to proper irrigation systems.",
    "Imagine a community where public services are underfunded.",
    "Imagine a city grappling with high property taxes.",
    "Imagine a family without access to affordable healthcare.",
    "Imagine a neighborhood facing frequent burglaries.",
    "Imagine a town where public transport is inaccessible for disabled individuals.",
    "Imagine a rural area without access to modern communication systems.",
    "Imagine a community grappling with public safety concerns.",
    "Imagine a city with decaying infrastructure.",
    "Imagine a school without a proper library.",
    "Imagine a hospital with outdated equipment.",
    "Imagine a village without proper waste disposal services.",
    "Imagine a community suffering from frequent heatwaves.",
    "Imagine a neighborhood without a safe space for children to play.",
    "Imagine a town dealing with high rates of teen pregnancy.",
    "Imagine a rural area without access to veterinary services.",
    "Imagine a city with limited access to affordable housing.",
    "Imagine a family dealing with food insecurity.",
    "Imagine a neighborhood with poor road conditions.",
    "Imagine a town facing frequent water contamination.",
    "Imagine a village struggling with access to basic healthcare.",
    "Imagine a rural area affected by lack of education resources.",
    "Imagine a community dealing with a rise in substance abuse.",
    "Imagine a city where public transportation is unreliable.",
    "Imagine a neighborhood with frequent noise from nearby industries.",
    "Imagine a town without access to affordable legal services.",
    "Imagine a family living in fear of eviction due to rising rents.",
    "Imagine a rural area struggling with insufficient rainfall.",
    "Imagine a school with inadequate sports facilities.",
    "Imagine a community facing issues with racial inequality.",
    "Imagine a hospital facing frequent equipment failures.",
    "Imagine a city grappling with traffic-related pollution.",
    "Imagine a neighborhood dealing with power grid instability."
];

  const [currentMessage, setCurrentMessage] = useState(messages[0]);
  const [opacity, setOpacity] = useState(1); // Control opacity for fade effect
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMessage((prevMessage) => {
        // Random index for the next message
        const randomIndex = Math.floor(Math.random() * messages.length);
        const nextIndex = (messages.indexOf(prevMessage) + 1) % messages.length;
        return messages[randomIndex];
      });
    }, 5000); // Update every 5 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="text-5xl dynamic-text font-bold bg-clip-text text-transparent b
    -gradient-to-r from-purple-500 to-gray-500">
      {currentMessage}
    </div>
  );
};

  // Print the random base 64 32 character string to the console
  return (
    <main className="m-5 margin-auto flex flex-col">
      <div className="flex flex-col">
        <div className="flex flex-col">
        <img className="h-20 w-auto" src="projectCivilian.svg" alt="" />
        <div className="flex flex-col text-center mx-auto">
        <div className="min-h-screen bg-gray-100">
      {/* Hero Section */}
      {/* Hero Section */}
      <section className="hero text-black py-20">
              <div className="container mx-auto px-4 text-center">
                <h1 className="text-3xl md:text-4xl font-bold mb-4 welcome">Welcome to projectCivilian.org</h1>
                <p className="text-lg md:text-xl mb-8">Connecting you directly with your government officials and using AI to solve local problems.</p>
                <a
                  onClick={() => {
                    // Scroll to the about section.
                    document.getElementById('about').scrollIntoView({ behavior: 'smooth' });
                  }}
                  href="#about"
                  id="hero"
                  className="bg-white text-blue-600 hover:bg-grey-100 px-6 py-3 rounded-full font-semibold"
                >
                  Explore More
                </a>
              </div>
              </section>

              {/* About Section */}
              <section id="about" className="py-16 bg-grey-900">
                <div className="container mx-auto px-4">
                  <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 text-grey-800">What You Can Do</h2>
                  <p className="text-lg md:text-xl text-gray-600 text-center mb-8">
                    ProjectCivilian.org aims to empower citizens by providing a direct line to their government officials. 
                    We use AI to identify, analyze, and provide solutions to specific problems, 
                    address grievances, and express dissatisfaction in a constructive manner. Whether it's local issues or 
                    broader concerns, our platform ensures your voice is heard and acted upon effectively.
                  </p>
                </div>
                <div>              
                  <a
                    onClick={() => {props.setIsOnboardOpen(false);}}
                    href="#explore"
                    className="bg-white text-blue-600 hover:bg-grey-100 px-6 py-3 rounded-full font-semibold"
                  >I want to see it first.</a>
                   <br/>
                   <br/>
                <a
                    onClick={() => {props.setIsRegisterPaneOpen(true); props.setIsOnboardOpen(false);}}
                    href="#about"
                    className="bg-white text-purple-600 hover:bg-grey-100 px-6 py-3 rounded-full font-semibold clear-both"
                  >I'm sold, sign me up!</a>

                </div>
              </section>

              {/* Use Case Section */}
              <section className="use-case py-16 bg-gray-200">
                <div className="container mx-auto px-4">
                  <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 text-gray-800">Use Case</h2>
                  <div className="text-lg md:text-xl text-gray-700 text-center mb-8">
                    <DynamicText></DynamicText>
                    <p className="mt-4">Using ProjectCivilian.org, we can easily connect with local officials to report the issue. Our AI tool analyzes the problem, suggests potential solutions, and helps facilitate a constructive dialogue between citizens and officials to resolve the matter efficiently.
                    </p>
                  </div>
                  <a
                    onClick={() => {props.setIsOnboardOpen(false);}}
                    href="#explore"
                    className="bg-white text-blue-600 hover:bg-grey-100 px-6 py-3 rounded-full font-semibold"
                  >I want to see it first.</a>
                   <br/>
                   <br/>
                <a
                    onClick={() => {props.setIsRegisterPaneOpen(true); props.setIsOnboardOpen(false);}}
                    href="#about"
                    className="bg-white text-purple-600 hover:bg-grey-100 px-6 py-3 rounded-full font-semibold clear-both"
                  >I'm sold, sign me up!</a>

                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Onboard;
