import * as React from 'react';
import Map from 'react-map-gl';
// import { useContext } from 'react';
import '../App.css';
import Header from "./Header";
import Visible from "./Visible"
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import Onboard from './Onboard';
import Post from './Post';
import SelectedPosts from "./SelectedPosts";
import SinglePost from "./SinglePost";
import SlidingPane from "react-sliding-pane";
import Login from './Login';
import Register from './Register';
import Account from './Account';
import postToGeoJSON from '../utils/postToGeoJSON';
import PostMap from './PostMap';
import Footer from './Footer';
// Queries and Mutations
import { GET_ALL_POSTS } from '../utils/queries';
import { POST_CREATED, POST_DELETED, POST_UPDATED } from '../utils/subscriptions';
import { ToastContainer, toast } from 'react-toastify';
import { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Auth from '../utils/auth';
import getRandomPrompt from '../utils/getRandomPrompt';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import 'react-toastify/dist/ReactToastify.css';

import "react-sliding-pane/dist/react-sliding-pane.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';
const initialUser = Auth.getUserData().data?._id || null

const Main = React.memo(() => {
const postReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_INITIAL_POSTS':
      return action.post;
    case 'ADD_POST':
      // console.log("State:", state);
      return [...state, action.post];
    case 'DELETE_POST':
      // console.log("State:", state);
      return state.filter((post) => post._id !== action.post);
    case 'UPDATE_POST':
      return state.map((post) => (post._id === action.post._id ? action.post : post));
    case 'RESET_POSTS':
      return action.posts; // assuming that the original posts are passed as action.posts
    case 'FILTER_POSTS':
      return action.post;
    default:
      return state;
  }
}
  // Map states.
  // Paired in [ LONGITUDE, LATITUDE ] because OpenLayers uses the pair (Longitude before Latitude, i.e., toLonLat, fromLonLat).
  const GEOGRAPHIC_CENTER_OF_UNITED_STATES = [44.967243, -103.771556];
  const [coordinateRoundTo, setCoordinateRoundTo] = React.useState(3);
  // const [view, setView] = React.useState({ center: fromLonLat(GEOGRAPHIC_CENTER_OF_UNITED_STATES), zoom: 3, extent: [-20037508.34, 20037508.34, 20037508.34, -20037508.34] });
  const [centerLatitude, setCenterLatitude] = React.useState(GEOGRAPHIC_CENTER_OF_UNITED_STATES[0]);
  const [centerLongitude, setCenterLongitude] = React.useState(GEOGRAPHIC_CENTER_OF_UNITED_STATES[1]);
  const [viewport, setViewport] = React.useState({
    latitude: GEOGRAPHIC_CENTER_OF_UNITED_STATES[0],
    longitude: GEOGRAPHIC_CENTER_OF_UNITED_STATES[1],
    zoom: 1
  });
  const [postGeoJSON, setPostGeoJSON] = React.useState({
    "type": "FeatureCollection",
    "metadata": {
      "title": "Main Posts",
    },
    "features": []
  });
  // Bottom sliding pane state.
  let showOnboard = true;
  if (Auth.loggedIn()) {
    showOnboard = false;
  }
    
  const [isLoadingOpen, setIsLoadingOpen] = React.useState(false);
  const [isOnboardOpen, setIsOnboardOpen] = React.useState(showOnboard);
  const [isPostPaneOpen, setIsPostPaneOpen] = React.useState(false);
  const [isRegisterPaneOpen, setIsRegisterPaneOpen] = React.useState(false);
  const [isLoginPaneOpen, setIsLoginPaneOpen] = React.useState(false);
  const [isAccountPaneOpen, setIsAccountPaneOpen] = React.useState(false);
  const [isSelectedPaneOpen, setIsSelectedPaneOpen] = React.useState(false);
  const [isSinglePostOpen, setIsSinglePostOpen] = React.useState(false);
  const [isVisiblePaneOpen, setIsVisiblePaneOpen] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [editUser, setEditUser] = React.useState();
  const [editPostId, setEditPostId] = React.useState();
  const [editPostBody, setEditPostBody] = React.useState();
  const [currentUser, setCurrentUser] = React.useState(initialUser);
  //For clustering posts.
  const [distance, setDistance] = React.useState(35);
  const [selected, setSelected] = React.useState("Click a cluster for details");

  // For managing the user's current position.
  const [currentLatitude, setCurrentLatitude] = React.useState();
  const [currentLongitude, setCurrentLongitude] = React.useState();
  const [accuracy, setAccuracy] = React.useState();

  // For managing user clicks and date.
  const [selectedMapPosts, setSelectedMapPosts] = React.useState([]);

  // Getting posts.
  const { data: initialData, loading: initialLoading, error: initialError } = useQuery(GET_ALL_POSTS); // Getting posts.
  const { data: latestData, loading: latestLoading } = useSubscription(POST_CREATED);
  const { data: postDeletedData, loading: postDeletedLoading } = useSubscription(POST_DELETED);
  const { data: postUpdatedData, loading: postUpdatedLoading } = useSubscription(POST_UPDATED);
  
  // const [posts, setPosts] = React.useState([]);

  const [posts, dispatch] = React.useReducer(postReducer, []);
  const [postLength, setPostLength] = React.useState(0); // Create a reference to hold the full list of posts
  const [savedPosts, setSavedPosts] = React.useState([]); // Create a reference to hold the full list of posts
  const [searchInProgress, setSearchInProgress] = React.useState(false); // Create a reference to hold the full list of posts

  const [gpsSnackbarOpen, setGpsSnackbarOpen] = useState(false);
  const [introSnackbarOpen, setIntroSnackbarOpen] = useState(true);
  const [postSnackbarOpen, setPostSnackbarOpen] = useState(false);
  const [postSnackbarText, setPostSnackbarText] = useState("Post updating...");
  const [randomPrompt, setRandomPrompt] = useState(getRandomPrompt());
  // For filtering posts.
  const [incomingPost, setIncomingPost] = React.useState();
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState('Search');
  const [searchMade, setSearchMade] = useState(false); // Add this line
  const [searchParameter, setSearchParameter] = useState(new URLSearchParams(window.location.search).get('search')); // Add this line
  const theme = createTheme({
    palette: {
      primary: {
        main: '#D6E4DD',
        // light: will be calculated from palette.primary.main,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      }
    },
  });
  const filterData = async (searchTerm) => {
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      console.log(searchTermLower);
      setSearchInProgress(true);
      if (savedPosts.length === 0) {
        setSavedPosts(posts);
      }
      const filteredPosts = posts?.filter(post => {
        console.log(post)
          return post?.body?.toLowerCase().includes(searchTermLower) || 
          post.civicInfo?.toLowerCase().includes(searchTermLower) || 
          post?.user?.username?.toLowerCase().includes(searchTermLower) ||
          post?._id?.toLowerCase().includes(searchTermLower) || 
          JSON.stringify(post.solutions).toLowerCase().includes(searchTermLower) || 
          JSON.stringify(post.issues).toLowerCase().includes(searchTermLower)
        }
      );
      // console.log(filteredPosts);
      dispatch({ type: 'FILTER_POSTS', post: filteredPosts });
    } else {
      dispatch({ type: 'RESET_POSTS', posts: savedPosts })
      setSearchInProgress(false);
    }
  };
  
  React.useEffect(() => {
    setCenterLatitude(viewport.latitude);
    setCenterLongitude(viewport.longitude);
  }, [viewport]);

  
  React.useEffect(() => {
    async function getAllPosts() {
      if (initialData && !initialLoading) {
        dispatch({ type: 'ADD_INITIAL_POSTS', post: initialData.posts });
        if (searchParameter) {
          setSearchText(searchParameter);
          setSearch(searchParameter);
          filterData(searchParameter);
          console.log("Search Parameter:", searchParameter);
          console.log('FilterData done.');
        }
      }
    }

    async function filterSearchTerm() {
      if (searchParameter) {
        setSearchText(searchParameter);
        setSearch(searchParameter);
        filterData(searchParameter);
        console.log("Search Parameter:", searchParameter);
        console.log('FilterData done.');
      }
    }
    getAllPosts();
    filterSearchTerm();
    
    
  }, [initialLoading, initialData]);



  // Set the initial posts when data is loaded
  // React.useEffect(() => {
  //   if (!loading && data) {
  //     setPosts(data.posts);
  //   }
  // }, [loading, data]);

  // Add a post when a new one is created
  React.useEffect(() => {
    const getLatestData = async () => {
      try {
        if (!latestLoading && latestData) {
          if (incomingPost) {
            if (latestData?.postCreated.body === incomingPost) {
              console.log("Post arrived!");
            } else {
              console.log("Latest Data:", latestData?.postCreated.body);
              console.log("Incoming Post:", incomingPost);
            }
          }
          dispatch({ type: 'ADD_POST', post: latestData.postCreated });
          // setViewport({latitude: latestData.postCreated.latitude, longitude: latestData.postCreated.longitude, zoom: 20})
          setIncomingPost(null);
          setPostSnackbarText("Post created! Click to zoom.");
          // setPostSnackbarOpen(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getLatestData();
  }, [latestLoading, latestData]);

  // Remove a post when it's deleted
  React.useEffect(() => {
    const getDeletedData = async () => {
      try {
        if (!postDeletedLoading && postDeletedData) {
          // console.log("Deleting:", postDeletedData);
          // console.log("From:", posts);
          dispatch({ type: 'DELETE_POST', post: postDeletedData.postDeleted });
        }
      } catch (error) {
        // console.log(error);
      }
    };
    getDeletedData();
  }, [postDeletedLoading, postDeletedData]);

  // Update a post when it's updated
  React.useEffect(() => {
    const getUpdatedData = async () => {
      try {
        if (!postUpdatedLoading && postUpdatedData) {
          dispatch({ type: 'UPDATE_POST', post: postUpdatedData.postUpdated });
        }
      } catch (error) {
        // console.log(error);
      }
    };
    getUpdatedData();
  }, [postUpdatedLoading, postUpdatedData]);

  React.useEffect(() => {
    async function setPostData() {
      try {
        if (posts) {
          const postData = postToGeoJSON(posts);
          setPostGeoJSON(postData);
          setPostLength(posts.length);
        }
      } catch (error) {
        // console.log(error);
      }
    }
    setPostData();
  }, [posts]);
//   React.useEffect(() => {
//     // This function checks if the user is logged in and updates currentUser accordingly
//     const checkLoginStatusAndUpdateUser = () => {
//       if (Auth.loggedIn()) {
//         // If logged in, set the currentUser to Auth.user._id
//         setCurrentUser(Auth.user._id);
//       } else {
//         // If not logged in, reset currentUser to null or any other default value
//         setCurrentUser(null);
//       }
//     }
//     checkLoginStatusAndUpdateUser();
//   return () => {
//     // Auth.off('loginStatusChanged', checkLoginStatusAndUpdateUser);
//   };
// }, [/* dependencies here, if any */]);
  return (
    <>
    <div key='app' className="parentContainer"  style={{overflow: "hidden", overflowY: "auto"}} >
      <Header 
        key="header"
        theme={theme}
        filterData={filterData}
        setSearch={setSearch}
        searchText={searchText}
        setSearchText={setSearchText}
        search={search}
        searchMade={searchMade}
        setSearchMade={setSearchMade}
        postLength={posts.length}
        viewport={viewport}
        setViewport={setViewport}
        centerLatitude={centerLatitude}
        centerLongitude={centerLongitude}
        currentLatitude={currentLatitude}
        currentLongitude={currentLongitude}
        setCenterLatitude={setCenterLatitude}
        setCenterLongitude={setCenterLongitude}
        setCurrentLatitude={setCurrentLatitude}
        setCurrentLongitude={setCurrentLongitude}
        coordinateRoundTo={coordinateRoundTo}
        isOnboardOpen={isOnboardOpen}
        setIsOnboardOpen={setIsOnboardOpen}
        isPostPaneOpen={isPostPaneOpen}
        setIsPostPaneOpen={setIsPostPaneOpen}
        isLoginPaneOpen={isLoginPaneOpen}
        isAccountPaneOpen={isAccountPaneOpen}
        setIsAccountPaneOpen={setIsAccountPaneOpen}
        setIsLoginPaneOpen={setIsLoginPaneOpen}
        isRegisterPaneOpen={isRegisterPaneOpen}
        setIsRegisterPaneOpen={setIsRegisterPaneOpen}
        isVisiblePaneOpen={isVisiblePaneOpen}
        setIsVisiblePaneOpen={setIsVisiblePaneOpen}
        gpsSnackbarOpen={gpsSnackbarOpen}
        setGpsSnackbarOpen={setGpsSnackbarOpen}
      />
      <PostMap
        key="map"
        className="postMap"
        postLength={postLength}
        viewport={viewport}
        setViewport={setViewport}
        setCenterLatitude={setCenterLatitude}
        setCenterLongitude={setCenterLongitude}
        currentLatitude={currentLatitude}
        currentLongitude={currentLongitude}
        setCurrentLatitude={setCurrentLatitude}
        setCurrentLongitude={setCurrentLongitude}
        postGeoJSON={postGeoJSON}
        setSelectedMapPosts={setSelectedMapPosts}
        selectedMapPosts={selectedMapPosts}
        setIsSelectedPaneOpen={setIsSelectedPaneOpen}
        isSelectedPaneOpen={isSelectedPaneOpen}
        setIsPostPaneOpen={setIsPostPaneOpen}
        setIsRegisterPaneOpen={setIsRegisterPaneOpen}
        setIsEditMode={setIsEditMode}
      />
    </div>
    <Footer
      filterData={filterData}
      setSearch={setSearch}
      searchText={searchText}
      setSearchText={setSearchText}
      search={search}
      searchMade={searchMade}
      setSearchMade={setSearchMade}
      postLength={posts.length}
      viewport={viewport}
      setViewport={setViewport}
      centerLatitude={centerLatitude}
      centerLongitude={centerLongitude}
      currentLatitude={currentLatitude}
      currentLongitude={currentLongitude}
      setCenterLatitude={setCenterLatitude}
      setCenterLongitude={setCenterLongitude}
      setCurrentLatitude={setCurrentLatitude}
      setCurrentLongitude={setCurrentLongitude}
      coordinateRoundTo={coordinateRoundTo}
      isOnboardOpen={isOnboardOpen}
      setIsOnboardOpen={setIsOnboardOpen}
      isPostPaneOpen={isPostPaneOpen}
      setIsPostPaneOpen={setIsPostPaneOpen}
      isLoginPaneOpen={isLoginPaneOpen}
      setIsAccountPaneOpen={setIsAccountPaneOpen}
      setIsLoginPaneOpen={setIsLoginPaneOpen}
      isRegisterPaneOpen={isRegisterPaneOpen}
      setIsRegisterPaneOpen={setIsRegisterPaneOpen}
      isVisiblePaneOpen={isVisiblePaneOpen}
      setIsVisiblePaneOpen={setIsVisiblePaneOpen}
      gpsSnackbarOpen={gpsSnackbarOpen}
      setGpsSnackbarOpen={setGpsSnackbarOpen}
    ></Footer>
    <>
    <Dialog
        className={"dialog  sm:w-1/2"}

        open={isOnboardOpen}
      >
        <Onboard
          style={{ zIndex: -10, transition: "none !important"}}
          key="selected"
          update={true}
          filterData={filterData}
          setSearch={setSearch}
          searchText={searchText}
          setSearchText={setSearchText}
          search={search}
          searchMade={searchMade}
          setSearchMade={setSearchMade}
          centerLatitude={centerLatitude}
          centerLongitude={centerLongitude}
          setEditUser={setEditUser}
          setEditPostId={setEditPostId}
          setEditPostBody={setEditPostBody}
          isOnboardOpen={isOnboardOpen}
          setIsOnboardOpen={setIsOnboardOpen}
          isRegisterPaneOpen={isRegisterPaneOpen}
          setIsRegisterPaneOpen={setIsRegisterPaneOpen}
          selectedMapPosts={selectedMapPosts}
          setSelectedMapPosts={selectedMapPosts}
          setIsSelectedPaneOpen={setIsSelectedPaneOpen}
          isSelectedPaneOpen={isSelectedPaneOpen}
          setIsVisiblePaneOpen={setIsVisiblePaneOpen}
          setIsPostPaneOpen={setIsPostPaneOpen}
          setIsEditMode={setIsEditMode}
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
          setViewport={setViewport}
          viewport={viewport}
        ></Onboard>
         <div className={"mx-auto close-button"}>
          <IconButton
              className={"mx-auto"}
              color="black"
              onClick={()=> {
                setIsOnboardOpen(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
      </Dialog>
      <Dialog
        className={"dialog"}
        
        open={isSelectedPaneOpen}
      >
        <SelectedPosts
          style={{ zIndex: -10, transition: "none !important"}}
          key="selected"
          update={true}
          filterData={filterData}
          setSearch={setSearch}
          searchText={searchText}
          setSearchText={setSearchText}
          search={search}
          searchMade={searchMade}
          setSearchMade={setSearchMade}
          centerLatitude={centerLatitude}
          centerLongitude={centerLongitude}
          setEditUser={setEditUser}
          setEditPostId={setEditPostId}
          setEditPostBody={setEditPostBody}
          selectedMapPosts={selectedMapPosts}
          setSelectedMapPosts={selectedMapPosts}
          setIsSelectedPaneOpen={setIsSelectedPaneOpen}
          isSelectedPaneOpen={isSelectedPaneOpen}
          setIsLoginPaneOpen={setIsLoginPaneOpen}
          setIsVisiblePaneOpen={setIsVisiblePaneOpen}
          setIsPostPaneOpen={setIsPostPaneOpen}
          setIsEditMode={setIsEditMode}
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
          setViewport={setViewport}
          viewport={viewport}
        ></SelectedPosts>
         <div className={"mx-auto close-button"}>
          <IconButton
              className={"mx-auto"}
              color="black"
              onClick={()=> {
                setIsSelectedPaneOpen(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
      </Dialog>
  {/* Register Dialog */}
  <Dialog className={"dialog"}  open={isRegisterPaneOpen}>
    <div className={"flex justify-center items-center mx-auto close-button"}>
      <IconButton
          className={"mx-auto"}
          style={{margin: '0'}}
          edge="start"
          color="black"
          onClick={()=> { setIsRegisterPaneOpen(false); }}
          aria-label="close"
        >
        <CloseIcon />
      </IconButton>
    </div>
    <Register 
      setIsLoginPaneOpen={setIsLoginPaneOpen}
      setIsRegisterPaneOpen={setIsRegisterPaneOpen}
    />
  </Dialog>
  <Dialog className={"dialog"}  open={isAccountPaneOpen}>
    <div className={"flex justify-center items-center mx-auto close-button"}>
      <IconButton
          className={"mx-auto"}
          style={{margin: '0'}}
          edge="start"
          color="black"
          onClick={()=> { setIsAccountPaneOpen(false); }}
          aria-label="close"
        >
        <CloseIcon />
      </IconButton>
    </div>
    <Account 
      setIsLoginPaneOpen={setIsLoginPaneOpen}
      setIsRegisterPaneOpen={setIsRegisterPaneOpen}
    />
  </Dialog>

  {/* Post Dialog - Not Edit Mode */}
  {!isEditMode &&
    <Dialog className={"dialog"}  open={isPostPaneOpen}>
      <div className={"flex justify-center items-center mx-auto close-button"}>
        <IconButton
            className={"mx-auto"}
            style={{margin: '0'}}
            edge="start"
            color="black"
            onClick={()=> { setIsPostPaneOpen(false); }}
            aria-label="close"
          >
          <CloseIcon />
        </IconButton>
      </div>
      <Post 
        key="post"
        {...{ currentUser, setCurrentUser, currentLatitude, currentLongitude, centerLatitude, centerLongitude, setIsPostPaneOpen, setPostGeoJSON, postGeoJSON, setPostSnackbarOpen, incomingPost, setIncomingPost }}
      />
    </Dialog>
  }

  {/* Post Dialog - Edit Mode */}
  {isEditMode &&
    <Dialog className={"dialog"}  open={isPostPaneOpen}>
      <div className={"flex justify-center items-center mx-auto close-button"}>
        <IconButton
            className={"mx-auto"}
            style={{margin: '0'}}
            edge="start"
            color="black"
            onClick={()=> { setIsPostPaneOpen(false); }}
            aria-label="close"
          >
          <CloseIcon />
        </IconButton>
      </div>
      <Post
        key="post-edit"
        update={true}
        {...{ editUser, editPostId, editPostBody, currentUser, setCurrentUser, currentLatitude, currentLongitude, centerLatitude, centerLongitude, setIsPostPaneOpen, setPostGeoJSON, postGeoJSON, setPostSnackbarOpen }}
      />
    </Dialog>
  }

  {/* Login Dialog */}
  <Dialog className={"dialog"}  open={isLoginPaneOpen}>
    <div className={"flex justify-center items-center mx-auto close-button"}>
      <IconButton
          className={"mx-auto"}
          style={{margin: '0'}}
          edge="start"
          color="black"
          onClick={()=> { setIsLoginPaneOpen(false); }}
          aria-label="close"
        >
        <CloseIcon />
      </IconButton>
    </div>
    <Login 
      key="login"
      {...{ currentUser, setCurrentUser, setIsLoginPaneOpen, setIsRegisterPaneOpen }}
    />
  </Dialog>

  {/* Single Post Dialog */}
  <Dialog className={"dialog"}  open={isSinglePostOpen}>
    <div className={"flex justify-center items-center mx-auto close-button"}>
      <IconButton
          className={"mx-auto"}
          style={{margin: '0'}}
          edge="start"
          color="black" 
          onClick={()=> { setIsSinglePostOpen(false); }}
          aria-label="close"
        >
        <CloseIcon />
      </IconButton>
    </div>
    <SinglePost
      key="singlePost"
      update={true}
      {...{ centerLatitude, centerLongitude, setEditUser, setEditPostId, setEditPostBody, selectedMapPosts, setSelectedMapPosts, setIsSelectedPaneOpen, isSelectedPaneOpen, setIsPostPaneOpen, isSinglePostOpen, setIsSinglePostOpen, setIsEditMode, setIsVisiblePaneOpen, setViewport, currentUser, setCurrentUser, viewport }}
    />
  </Dialog>

  {/* Visibility Pane Dialog */}
  <Dialog className={"dialog"} open={isVisiblePaneOpen}>
    <div className={"flex justify-center items-center mx-auto close-button"}>
      <IconButton
          className={"mx-auto"}
          style={{margin: '0'}}
          edge="start"
          color="black"
          onClick={()=> { setIsVisiblePaneOpen(false); }}
          aria-label="close"
        >
        <CloseIcon />
      </IconButton>
    </div>
    <Visible
      key="history"
      update={true}
      postGeoJSON={postGeoJSON}
      setSelectedMapPosts={setSelectedMapPosts}
      selectedMapPosts={selectedMapPosts}
      setIsSelectedPaneOpen={setIsSelectedPaneOpen}
      filterData={filterData}
      setSearch={setSearch}
      searchText={searchText}
      setSearchText={setSearchText}
      search={search}
      searchMade={searchMade}
      setSearchMade={setSearchMade}
      centerLatitude={centerLatitude}
      centerLongitude={centerLongitude}
      setEditUser={setEditUser}
      setEditPostId={setEditPostId}
      setEditPostBody={setEditPostBody}
      isSelectedPaneOpen={isSelectedPaneOpen}
      setIsVisiblePaneOpen={setIsVisiblePaneOpen}
      setIsPostPaneOpen={setIsPostPaneOpen}
      setIsEditMode={setIsEditMode}
      currentUser={currentUser}
      setCurrentUser={setCurrentUser}
      setViewport={setViewport}
      viewport={viewport}
    />
  </Dialog>

</>
      <Snackbar
          onClose={() => setGpsSnackbarOpen(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={gpsSnackbarOpen}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setGpsSnackbarOpen(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>

            </React.Fragment>
          }
          key='setGpsSnackbar'
          message="Your GPS location is hidden."
      >
      </Snackbar>
      <Snackbar
          onClose={() => setIntroSnackbarOpen(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          style={{fontSize: '1em'}}
          open={introSnackbarOpen}
          ClickAwayListenerProps={{ onClickAway: () => null }}

          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setIntroSnackbarOpen(false)}>
                <CloseIcon fontSize="medium" />
              </IconButton>
            </React.Fragment>
          }
          key='introSnackbar'
          message={"Click the green dot to sign up & post a problem."}
      />   
      <Snackbar
          onClose={() => setPostSnackbarOpen(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClick={() => {
            if (latestData?.postCreated) {
              setPostSnackbarText("Post created! Click to zoom.");
              setSelectedMapPosts([latestData.postCreated]);
              setViewport({latitude: latestData.postCreated.latitude, longitude: latestData.postCreated.longitude, zoom: 17})
            }
          }}
          open={postSnackbarOpen}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setPostSnackbarOpen(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
          key='postSnackbar'
          message={postSnackbarText} 
        />
    </>
  )
});

export default Main;